import Div from "@components/elements/div"
import Paragraph from "@components/elements/paragraph"
import React from "react"
import Image from "@components/image"
import Heading from "@components/elements/heading"
import { graphql, useStaticQuery } from "gatsby"
import { processImages } from "@utils/process-images"
import PropTypes from "prop-types"
import { PowerMetricsPricingFAQ } from "./faq"
import { PowerMetricsAddOns } from "./add-ons"
import { PowerMetricsServices } from "./services"

export const PowerMetricsDetails = ({ handleClick, microsite }) => {
  const { data } = useStaticQuery(graphql`
    query PowerMetricsTable {
      data: allDirectusFiles(
        filter: {
          directus_id: { in: ["1192b40b-c5b0-4768-b1af-a5d5c1555cc0"] }
        }
      ) {
        edges {
          node {
            directus_id
            cdn
            id
            placeholder
            title
          }
        }
      }
    }
  `)
  const images = processImages(data.edges)
  const servicesPage = microsite ? "/company/services" : "/services"

  return (
    <Div background="white">
      <PowerMetricsAddOns handleClick={handleClick} />

      <PowerMetricsServices servicesPage={servicesPage} />

      <Div>
        <Paragraph
          fontWeight={600}
          fontSize="1.5rem"
          lineHeight={1.5}
          maxWidth="800px"
          margin="0 auto 2rem"
          center
          style={{ position: "relative", zIndex: 12 }}
        >
          Recognized as <b>most recommended</b> (Software Advice) and a&nbsp;
          <b>category leader</b> (G2 Crowd) in Analytics and Business
          Intelligence.
        </Paragraph>

        <Image
          style={{
            margin: "0 auto",
            padding: "0 2rem",
            maxWidth: "600px",
          }}
          file={images["1192b40b-c5b0-4768-b1af-a5d5c1555cc0"]}
        />
      </Div>

      <Div
        margin="8rem auto"
        marginMd="6rem auto"
        marginSm="4rem auto"
        textContainer
      >
        <Heading as="h2" fontSize="3rem" center margin="0 0 2rem">
          Frequently Asked Questions
        </Heading>
        <PowerMetricsPricingFAQ
          handleClick={handleClick}
          servicesPage={servicesPage}
        />
      </Div>
    </Div>
  )
}

PowerMetricsDetails.propTypes = {
  handleClick: PropTypes.func.isRequired,
  microsite: PropTypes.bool,
}

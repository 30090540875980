import React, { useCallback, useEffect, useState } from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { processImages } from "@utils/process-images"
import { PricingIcons } from "@components/pages/pricing/pricing-icons"
import { AnimatePresence, motion } from "framer-motion"
import { ProductSelector } from "@components/pages/pricing/selector"
import Div from "@components/elements/div"
import { useLocation } from "@hooks/use-location"
import { PRODUCTS } from "@hooks/use-product"
import styled, { useTheme } from "styled-components"
import { GetADemoForm } from "@components/forms/get-a-demo"
import { PowerMetricsEOPCta } from "@components/cta/eop/powermetrics"
import Grid from "@components/elements/grid"
import Paragraph from "@components/elements/paragraph"
import Anchor from "@components/elements/anchor"
import { useNeutralGradient } from "@hooks/use-neutral-gradient"
import { KlipsDetails } from "@components/pages/pricing/klips/details"
import { KlipsEOPCta } from "@components/cta/eop/klips"
import { KlipsPricing } from "@components/pages/pricing/klips/pricing"
import { FadeHeading } from "@components/elements/motion/heading"
import { MotionDiv } from "@components/elements/motion"
import { FollowGradient, FollowGradientAlt } from "@components/common/gradient"
import { PowerMetricsDetails } from "@components/pages/pricing/powermetrics/details"
import { PowerMetricsTable } from "@components/pages/pricing/powermetrics/table"

const PricingGrid = styled(Grid)`
  grid-template-columns: min-content auto;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`

const StyledBackground = styled(Div)`
  background: ${({ initialProduct }) =>
    initialProduct === "klips"
      ? "linear-gradient(#013950, #012330, #21264f, #0f1124)"
      : "linear-gradient(#21264f, #0f1124, #013950, #012330)"};
  background-size: auto 200%;
  background-position: 0
    ${({ klips, initialProduct }) =>
      (initialProduct === "klips" && !klips) ||
      (initialProduct !== "klips" && klips)
        ? "100%"
        : "0%"};
  transition: background-position 3s, background-color 1s;
  clip-path: inset(0 0 0 0);
  position: relative;
  padding: 9rem 0 0;
`

// TODO: Swap to use two separate pages, use canonical tag to make them both /pricing
const PricingPage = ({ data }) => {
  const location = useLocation()
  const images = processImages(data.images.edges)
  const params = new URLSearchParams(location.search)
  const [product, setProduct] = useState()
  const [type, setType] = useState()
  const [klips, setKlips] = useState(false)
  const [render, setRender] = useState(false)
  const [initialProduct, setInitialProduct] = useState()

  const { color } = useTheme()
  const [modalOpen, setModalOpen] = useState(false)

  const handleClick = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  const [monthly, setMonthly] = useState(true)

  const handleMonthlyClick = useCallback(() => {
    setMonthly(true)
  }, [])
  const handleYearlyClick = useCallback(() => {
    setMonthly(false)
  }, [])

  const { gradient } = useNeutralGradient()

  useEffect(() => {
    setProduct(params.get("product"))
    setKlips(params.get("product") === "klips")
    setType(params.get("type"))
    setInitialProduct(params.get("product"))
  }, [])

  useEffect(() => {
    !render && setRender(true)
  }, [product, render])

  useEffect(() => {
    const newProduct = params.get("product")
    const newType = params.get("type")

    if (newProduct !== product) {
      setProduct(newProduct)
      setKlips(newProduct === "klips")
    }

    if (newType !== type) {
      setType(newType)
    }
  }, [params])

  return (
    <Layout
      title={`Business Dashboards and Reporting Software for Everyone`}
      description={`Klipfolio is the leading platform for self-service BI, dashboards, and reporting. From startups to Fortune 500 teams, Klipfolio provides the power and flexibility you always wanted at an affordable price. Succeed with data analytics today and start for FREE.`}
      fullWidth
      marginless
      productOverride={klips ? PRODUCTS.KLIPS : PRODUCTS.POWERMETRICS}
      hideOverflow
      login
    >
      <GetADemoForm modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <StyledBackground klips={klips} initialProduct={initialProduct}>
        <AnimatePresence mode="wait">
          {render && (
            <motion.div
              key="initialRender"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              {initialProduct === "klips" ? (
                <FollowGradientAlt
                  flip={!klips}
                  top={"6rem"}
                  src={gradient.cdn}
                />
              ) : (
                <FollowGradient flip={klips} top={"6rem"} src={gradient.cdn} />
              )}
            </motion.div>
          )}
        </AnimatePresence>
        <Div position="relative" container>
          <header
            style={{
              margin: "0 auto",
              position: "relative",
              zIndex: 1,
            }}
          >
            <AnimatePresence mode="wait">
              <motion.div
                key="initialRender"
                initial={{ opacity: 0 }}
                animate={{ opacity: render ? 1 : 0 }}
                transition={{ duration: 1 }}
              >
                {!klips ? (
                  <FadeHeading
                    key="powerMetricsHeading"
                    center
                    maxWidth="35ch"
                    color={color.indigo200}
                    fontSize="2.8rem"
                    lineHeight="120%"
                    margin="0 auto 4rem"
                  >
                    Analytics designed for business teams with pricing that
                    works for you.
                  </FadeHeading>
                ) : (
                  <FadeHeading
                    key="klipsHeading"
                    center
                    maxWidth="35ch"
                    color={"#9EE6FA"}
                    fontSize="2.8rem"
                    lineHeight="120%"
                    margin="0 auto 4rem"
                  >
                    Custom dashboards,
                    <br />
                    affordable pricing.
                  </FadeHeading>
                )}
              </motion.div>
            </AnimatePresence>
          </header>
          <PricingGrid gap="1rem">
            <ProductSelector
              klips={klips}
              setKlips={setKlips}
              active={render}
            />
            <MotionDiv
              layout
              layoutRoot
              borderRadius="1.5rem"
              background="white"
              backgroundMd="none"
              overflow="hidden"
              transition={{
                duration: 0.5,
              }}
              margin="0 0 1rem"
            >
              <AnimatePresence initial={false} mode="wait">
                <motion.div
                  key="initialRender"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: render ? 1 : 0 }}
                  transition={{ duration: 1 }}
                >
                  {klips ? (
                    <motion.div
                      key="klipsPricing"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <KlipsPricing
                        images={images}
                        type={type}
                        monthly={monthly}
                        handleMonthlyClick={handleMonthlyClick}
                        handleYearlyClick={handleYearlyClick}
                      />
                    </motion.div>
                  ) : (
                    <motion.div
                      key="pmPricing"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <PowerMetricsTable
                        monthly={monthly}
                        handleClick={handleClick}
                        handleMonthlyClick={handleMonthlyClick}
                        handleYearlyClick={handleYearlyClick}
                      />
                    </motion.div>
                  )}
                </motion.div>
              </AnimatePresence>
            </MotionDiv>
          </PricingGrid>

          <Paragraph
            color="white"
            fontWeight={700}
            center
            lineHeight={1.4}
            fontSize="1rem"
            margin="1rem auto 5rem"
            maxWidth="1000px"
          >
            Prices shown in USD/mo, based on an annual subscription and billed
            annually. Enterprise plan is only available as an annual
            subscription.&nbsp;
            <Anchor
              color="white"
              link={"/services"}
              style={{ textDecoration: "underline" }}
            >
              Learn more
            </Anchor>
            &nbsp;about Onboarding, Training, Professional Services and Proof of
            Concept options.
          </Paragraph>
        </Div>
      </StyledBackground>

      {!klips ? (
        <>
          <PowerMetricsDetails />
          <PowerMetricsEOPCta
            force
            heading={"Make analytics everybody's business."}
          />
        </>
      ) : (
        <Div margin="6rem 0 0">
          <KlipsDetails />
          <KlipsEOPCta
            force
            heading={
              <span style={{ display: "block", maxWidth: "26ch" }}>
                Discover the power of Klips for you and your team
              </span>
            }
            description={
              "Try Klips for free for 14 days to uncover the features and discover what you can build."
            }
            padding="6rem 0"
          />
        </Div>
      )}
      <PricingIcons />
    </Layout>
  )
}

PricingPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query PricingPageQuery {
    images: allDirectusFiles(
      filter: { directus_id: { in: ["01c7bbb0-277d-499b-be8a-46c14c00f95f"] } }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`

export default PricingPage

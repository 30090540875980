import Div from "@components/elements/div"
import Paragraph from "@components/elements/paragraph"
import React from "react"
import Image from "@components/image"
import Heading from "@components/elements/heading"
import PropTypes from "prop-types"
import Flex from "@components/elements/flex"
import Anchor from "@components/elements/anchor"
import List from "@components/elements/list"
import { ServicesBlock } from "@components/common/services-block"
import { KlipsFAQ } from "@components/faq/klips"
import styled, { useTheme } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { processImages } from "@utils/process-images"

const LogoWrapper = styled.div`
  svg {
    max-width: 700px;
  }
`

export const KlipsDetails = () => {
  const { color, shadow } = useTheme()
  const { data } = useStaticQuery(graphql`
    query KlipsTable {
      data: allDirectusFiles(
        filter: {
          directus_id: { in: ["01c7bbb0-277d-499b-be8a-46c14c00f95f"] }
        }
      ) {
        edges {
          node {
            directus_id
            cdn
            id
            placeholder
            title
          }
        }
      }
    }
  `)
  const images = processImages(data.edges)

  return (
    <Div background="white">
      <Flex textContainer gap="2rem">
        <Div
          boxShadow={shadow.soft}
          background="white"
          padding="1.5rem"
          borderRadius="1rem"
        >
          <Paragraph color={color.blue600}>
            Interested in a custom plan?&nbsp;
            <Anchor color={color.blue400} link="/contact-us">
              Contact us
            </Anchor>
            . Our team is ready to help.
          </Paragraph>
        </Div>
        <Div
          boxShadow={shadow.soft}
          background="white"
          padding="1.5rem"
          borderRadius="1rem"
        >
          <Heading
            color={color.blue600}
            as="h3"
            fontSize="1.6rem"
            margin="0 0 1rem"
          >
            Top-Ups and Power-Ups
          </Heading>
          <Paragraph color={color.blue600} fontSize="1.1rem" margin="0 0 1rem">
            Choose from an extensive list of Top-Ups that give you room to grow
            and Power-Ups that support you every step of the way, such as:
          </Paragraph>
          <List
            margin="0 0 1rem 2rem"
            color={color.blue600}
            fontSize="1.1rem"
            gap="0rem"
          >
            <li>Add dashboards: $8/mo</li>
            <li>Near Real-time Data Refresh: $139/mo</li>
            <li>SSO (Single Sign-On): $49/mo</li>
            <li>Large Data Processing: Contact Us</li>
          </List>
          <Anchor
            arrow
            fontSize="1.1rem"
            link="https://www.klipfolio.com/klips-client-reporting/power-ups"
            color={color.blue400}
          >
            Learn more
          </Anchor>
        </Div>
      </Flex>
      <Div margin="8rem auto 0" marginMd="6rem auto 0" marginSm="4rem auto 0">
        <ServicesBlock background={color.blue100} text={color.blue600} />
      </Div>
      <Div margin="12rem 0" marginMd="8rem 0" marginSm="6rem 0" center>
        <Heading
          color={color.blue600}
          as="h3"
          maxWidthSm="25ch"
          margin="0 0 2rem"
          marginSm="0 auto 2rem"
        >
          Trusted by over 50,000 people across the world
        </Heading>
        <LogoWrapper>
          <Image
            style={{
              padding: "0 1rem",
              filter:
                "grayscale(100%) sepia(25%) saturate(2585%) hue-rotate(161deg) brightness(95%) contrast(103%)",
            }}
            objectFit="scale-down"
            file={images["01c7bbb0-277d-499b-be8a-46c14c00f95f"]}
          />
        </LogoWrapper>
      </Div>
      <KlipsFAQ />
    </Div>
  )
}

KlipsDetails.propTypes = {
  handleClick: PropTypes.func.isRequired,
  microsite: PropTypes.bool,
}
